<template>
  <div :class="['cy-tabs-container', { 'cy-tabs-container--fit-on-screen': fitOnScreen }]">
    <div
      v-if="!hideTabs"
      class="cy-tabs-nav px-5 mx-n8">
      <v-tabs
        class="flex-grow-0"
        :value="_.get(authorizedTabs[0], 'name')"
        :grow="false"
        background-color="transparent"
        show-arrows
        mandatory>
        <v-tabs-slider/>
        <v-tab
          v-for="{ name, icons } of authorizedTabs"
          :key="name"
          :ripple="false"
          :to="$route.name === name ? $route : { name }"
          @click="findClickAction(name)()">
          {{ $t(`routes.${name}`) }}
          <span
            v-for="({ name: iconName, color, tooltip }, index) of _.filter(icons, 'condition')"
            :key="`${iconName}-${index}`">
            <v-icon
              v-if="!tooltip"
              :color="color || 'primary'"
              class="ml-2"
              size="15">
              {{ iconName }}
            </v-icon>
            <CyTooltip
              v-else
              top>
              <template #activator="{ on }">
                <v-btn
                  :color="color || 'primary'"
                  class="px-2 ml-2"
                  x-small
                  icon
                  v-on="on">
                  <v-icon
                    size="15"
                    v-on="on">
                    {{ iconName }}
                  </v-icon>
                </v-btn>
              </template>
              <span>
                {{ tooltip }}
              </span>
            </CyTooltip>
          </span>
        </v-tab>
      </v-tabs>
      <CyBetaTag
        v-if="betaText"
        direction="left"
        :tooltip-text="betaText"/>
    </div>
    <router-view :class="['content', { 'flex-column': isColumn }]"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { constructBreadcrumb } from '@/utils/helpers'

export default {
  name: 'CyPageTabs',
  async beforeRouteEnter ({ name }, _from, next) {
    await next(async (vm) => {
      await vm.evaluatePermissions()
      if (name === vm.routeName) next({ ...vm.redirectRoute })
    })
  },
  async beforeRouteUpdate ({ name }, _from, next) {
    await this.evaluatePermissions()
    if (name === this.routeName) next({ ...this.redirectRoute })
    else next()
  },
  breadcrumb () {
    return constructBreadcrumb(this.$options.name, '...')
  },
  props: {
    routeName: {
      required: true,
      type: String,
    },
    hideTabs: {
      type: Boolean,
      default: false,
    },
    tabsIcons: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters('organization/billing', [
      'hasActiveBillingPlan',
    ]),
    betaText () {
      const route = _.find(this.$route.matched, ['name', this.routeName])
      return route?.meta?.isBeta ? this.$t(`routes.beta.${this.routeName}`) : null
    },
    fitOnScreen () {
      return _.get(this.$route, 'meta.layout.fitOnScreen', false)
    },
    isColumn () {
      return _.get(this.$route, 'meta.isListPage', false)
    },
    tabs () {
      const baseRoute = _.find(this.$route.matched, ['name', this.routeName])
      return baseRoute?.meta?.tabs?.() || []
    },
    authorizedTabs () {
      return this.tabs.reduce((accumulator, name) => {
        const canDisplayTab = name === 'workers'
          ? this.hasActiveBillingPlan
          : this.$cycloid.permissions.canDisplayRoute({ name })
        const icons = _.filter(this.tabsIcons, ({ targetTab }) => targetTab === name)
        if (canDisplayTab) accumulator.push({ name, icons })
        return accumulator
      }, [])
    },
    redirectRoute () {
      const { name } = this.authorizedTabs?.[0] || {}
      const { orgCanonical, $route: { path, meta: { backRouteTo, permissionsKey } } } = this
      return name
        ? { name, params: { orgCanonical }, replace: true }
        : { name: 'forbidden', params: { 0: path, backRouteTo, permissionsKey }, replace: true }
    },
  },
  methods: {
    async evaluatePermissions () {
      const requiredActions = this.tabs.reduce((accumulator, tab) => {
        accumulator.push(...this.$cycloid.permissions.getRouteRequiredActions({ name: tab }))
        return accumulator
      }, [])

      await this.$evaluateUserActions(requiredActions)
    },
    findClickAction (routeName) {
      const { route } = this.$router.resolve({ name: routeName }) || {}
      return route?.meta?.clickAction || _.noop
    },
  },
}
</script>

<style lang="scss" scoped>
.cy-tabs-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-content: stretch;
  height: 100%;
  min-height: 0;

  &--fit-on-screen {
    > .content {
      flex-grow: 1;
      min-height: 0;
      max-height: 100%;
    }
  }

  ::v-deep > .content:not(&) {
    display: flex;
    flex: 1 1 auto;
    margin-top: 24px;

    .content-wrapper--full-width & ::v-deep {
      .cy-details,
      .layout__content {
        width: 100%;
      }
    }
  }
}
</style>
