var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['cy-tabs-container', { 'cy-tabs-container--fit-on-screen': _vm.fitOnScreen }]},[(!_vm.hideTabs)?_c('div',{staticClass:"cy-tabs-nav px-5 mx-n8"},[_c('v-tabs',{staticClass:"flex-grow-0",attrs:{"value":_vm._.get(_vm.authorizedTabs[0], 'name'),"grow":false,"background-color":"transparent","show-arrows":"","mandatory":""}},[_c('v-tabs-slider'),_vm._l((_vm.authorizedTabs),function(ref){
var name = ref.name;
var icons = ref.icons;
return _c('v-tab',{key:name,attrs:{"ripple":false,"to":_vm.$route.name === name ? _vm.$route : { name: name }},on:{"click":function($event){_vm.findClickAction(name)()}}},[_vm._v(" "+_vm._s(_vm.$t(("routes." + name)))+" "),_vm._l((_vm._.filter(icons, 'condition')),function(ref,index){
var iconName = ref.name;
var color = ref.color;
var tooltip = ref.tooltip;
return _c('span',{key:(iconName + "-" + index)},[(!tooltip)?_c('v-icon',{staticClass:"ml-2",attrs:{"color":color || 'primary',"size":"15"}},[_vm._v(" "+_vm._s(iconName)+" ")]):_c('CyTooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"px-2 ml-2",attrs:{"color":color || 'primary',"x-small":"","icon":""}},on),[_c('v-icon',_vm._g({attrs:{"size":"15"}},on),[_vm._v(" "+_vm._s(iconName)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(tooltip)+" ")])])],1)})],2)})],2),(_vm.betaText)?_c('CyBetaTag',{attrs:{"direction":"left","tooltip-text":_vm.betaText}}):_vm._e()],1):_vm._e(),_c('router-view',{class:['content', { 'flex-column': _vm.isColumn }]})],1)}
var staticRenderFns = []

export { render, staticRenderFns }